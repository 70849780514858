import { useEffect, useState } from "react";
import { useAuthenticator, Divider } from "@aws-amplify/ui-react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MaterialLayout from "./Layout/MaterialLayout";
import { Link } from "@material-ui/core";
import { listCastlesurfNotices } from "./graphql/custom-queries";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

const backupNotices = [
  {
    id: "fa6800b8-b5e4-4ebc-be9d-b4af4971bd77",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527e23449",
    createdAt: "2024-09-26T22:34:14.339Z",
    groupId: "",
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/img/09.26.24+Updated+Project+Notice+%26+Schedule_page-0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/pdf/09.26.24+Updated+Project+Notice+%26+Schedule.pdf",
    title: "Updated Project Notice & Schedule",
    updatedAt: "2024-09-26T22:34:14.339Z",
  },
  {
    id: "607b0b0a-5d08-42e8-82ba-801c18c3ffe0",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527e23449",
    createdAt: "2024-07-11T22:34:14.339Z",
    groupId: "",
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/img/07.11.24LanaiClearanceNoticepage0001.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/pdf/07.11.24LanaiClearanceNotice.pdf",
    title: "Lanai Clearance Notice",
    updatedAt: "2024-07-11T22:34:14.339Z",
  },
  {
    id: "3ac8fb1e-ef70-4141-8560-014a2f19d587",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527e23449",
    createdAt: "2023-07-23T22:34:14.339Z",
    groupId: "",
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/img/1.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/pdf/Town+Hall+Presentation+Slides+-+April+19%2C+2023.pdf",
    title: "Town Hall Presentation Slides - April 19, 2023",
    updatedAt: "2023-07-23T22:34:14.339Z",
  },
  {
    id: "4ac707ae-2c8a-45cc-8e10-b6b7484df91a",
    __typename: "Notice",
    clientId: "9cf0d62b-479b-4f4d-9d16-558527e23449",
    createdAt: "2023-07-23T22:34:14.561Z",
    groupId: "",
    imgSource:
      "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/img/7.jpg",
    pdfSource:
      "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/castlesurf/pdf/Asbestos+Removal+Process+Flyer+-+REVISED.pdf",
    title: "Asbestos Removal Process - Castle Surf",
    updatedAt: "2023-07-23T22:34:14.561Z",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  cardstyle: {
    marginTop: theme.spacing(1),
    margin: "auto",
    flexDirection: "column",
  },
}));

function findMostRecentAndFormat(items) {
  const mostRecentItem = items.reduce((maxDateItem, currentItem) => {
    const currentDate = new Date(currentItem.updatedAt);
    const maxDate = new Date(maxDateItem.updatedAt);
    return currentDate > maxDate ? currentItem : maxDateItem;
  });

  const mostRecentDate = new Date(mostRecentItem.updatedAt);

  const formattedDate = `${(mostRecentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${mostRecentDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${mostRecentDate.getFullYear()}`;

  return formattedDate;
}

export const Bulletin = () => {
  const { signOut } = useAuthenticator();
  const classes = useStyles();

  const [castlesurfNotices, setcastlesurfNotices] = useState([]);
  const [mostRecentFormatted, setMostRecentFormatted] = useState();

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = async () => {
    try {
      const noticesData = await client.graphql({
        query: listCastlesurfNotices,
      });
      const noticesList = noticesData.data.listNotices.items;
      setMostRecentFormatted(findMostRecentAndFormat(noticesList));
      setcastlesurfNotices(noticesList);
    } catch (error) {
      setcastlesurfNotices(findMostRecentAndFormat(backupNotices));
      //console.error(error);
    }
  };

  return (
    <MaterialLayout>
      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
          textAlign: "center",
          backgroundColor: classes.root.backgroundColor,
        }}
      >
        <Typography variant="h4">Welcome!</Typography>
        <Button onClick={signOut}>
          <ExitToAppIcon /> Sign out
        </Button>
      </Paper>
      <Divider />
      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
        }}
      >
        <Typography variant="h6" align="center">
          Last update: {mostRecentFormatted}
        </Typography>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className="{classes.root}"
        >
          <List component="div" disablePadding>
            {castlesurfNotices.map((notice) => (
              <ListItem key={notice.id} className={classes.nested}>
                <Card className={classes.root}>
                  <CardActionArea href={notice.pdfSource} target="_blank">
                    <CardMedia
                      className={classes.media}
                      style={{ height: "200px", paddingTop: "2%" }}
                      image={notice.imgSource}
                      title={notice.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {notice.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {notice.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      <Link href={notice.pdfSource} target="_blank">
                        View Pdf
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </ListItem>
            ))}
          </List>
        </List>
      </Paper>
    </MaterialLayout>
  );
};
