export const listCastlesurfNotices = /* GraphQL */ `
  query ListCastlesurfNotices {
    listNotices(
      filter: { clientId: { eq: "9cf0d62b-479b-4f4d-9d16-558527e23449" } }
    ) {
      nextToken
      items {
        pdfSource
        title
        id
        imgSource
        updatedAt
      }
    }
  }
`;
