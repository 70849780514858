/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://3iixaks2avgmfj6voesfhykf7m.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vbuzkpqb3rdnvkmoewrrvaq3xi",
    "aws_cognito_identity_pool_id": "us-west-1:2918537e-9ea8-46ad-a723-a16d799811dc",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_mFodJHzkU",
    "aws_user_pools_web_client_id": "11jmbj8m95rgakokl3s8i1e04s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "darkstarcdn",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
